@import "./colors.css";

.clickable {
    cursor: pointer;
}

.clickableIcon {
    cursor: pointer;
}

.clickableIcon:hover {
    color: #2196f3 !important;
}

.showcase_container2 {
    height: 100vh;
}



.audioIframe {
    display: none;
}

.leftMenu {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 15;
}

.rightMenu {
    position: absolute;
    top: 20px;
    right: 60px;
    z-index: 15;
    font-size: 2.5rem;
}

.menuTitle {
    font-weight: 900;
    padding-top: 20px;
}

.menuCard {
    border-bottom: 1px solid white !important;
    border-radius: 0px !important;
    margin-left: 0.2rem !important;
    margin-right: 0.2rem !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.menuBody {
    background-color: transparent !important;
    padding: 0.25rem !important;
    font-size: 12px !important;
}

.menuCard>.card-header {
    background-color: transparent !important;
}

.menuBodyImgVid {
    background-color: transparent !important;
    padding: 0.25rem !important;
    font-size: 12px !important;
}

.shoppingPreviewModal {
    min-width: 1000px !important;
    border-radius: 0px;
    border: 0px;
}

.shoppingPreviewModal>.modal-content {
    border-radius: 0px;
    border: 0px;
    min-height: 600px;
    max-height: 700px;
    min-width: 1000px !important;
}

.productDetailModal {
    min-width: 1000px !important;
    border-radius: 0px;
    border: 0px;
}

.productDetailModal>.modal-content {
    border-radius: 0px;
    border: 0px;
    min-height: 500px;
    max-height: 700px;
    min-width: 1000px !important;
}

.productDetailGallery {
    width: 100%;
    max-height: 670px;
    min-height: 500px;
}

.cart {
    padding: 0.5rem;
    position: absolute;
    bottom: 0px !important;
    left: 12%;
    background: teal;
    color: white;
    opacity: 0.8;
    transition: 1s ease-out;
    width: 75%;
    border-radius: 0px;
    border: 0px;
}

.cart:active {
    opacity: 1;
}

.shoppingModalCloseIcon {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 555;
}

.sideMenuCloseIcon {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey;
    z-index: 555;
    position: relative;
    right: 20px;
    top: 20px;
    float: right;
}

.shoppingModalGallery {
    width: 100%;
    max-height: 670px;
}

.shoppingModalGalleryLabel {
    color: gray;
}

.shoppingTitle {
    font-family: "Montserrat, serif";
}

.shoppingDetailText {
    font-size: 12px;
}

.shoppingDescription {
    font-family: "ui-monospace";
}

.shoppingQuantity {
    font-family: "monospace";
}

.shoppingExtra {
    font-family: "Montserrat, serif";
}

.shoppingPrice {
    font-family: "monospace";
}

.shoppingPrice>del {
    color: red;
}

.pageModalContent {
    height: 680px;
}

.inactiveView {
    padding-top: 20%;
}

.pagePopupModal {
    width: 80% !important;
    max-width: 80% !important;
    border-radius: 0px;
    border: 0px;
}

.pagePopupModal>.modal-content {
    border-radius: 0px;
    border: 0px;
    min-height: 600px;
    max-height: 700px;
    height: 680px !important;
}

.pagePopupModal>.modal-content>.modal-body {
    padding: 0px;
}

.itemContent {
    max-height: 450px;
    overflow-x: scroll;
}

.sideMenuAccordion {
    margin: 0px !important;
}

.sideMenuSocialIcons {
    font-size: 28px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.socialItems{
    align-self: center !important;
}

.centerDisplay{
    margin-top: 300px;
    margin-bottom: 300px;
}

#lazy-load {
    height: 500px !important;
    width: 100% !important;
}

 #lazy-load-poster {
     position: absolute;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     background-size: contain;
     background-repeat: no-repeat;
     background-position: center;
 }

 #button-load {
     background-repeat: no-repeat;
     background-size: 24px 24px;
     background-position: 6% 50%;
     background-color: #000;
     color: white;
     cursor: pointer;
     border-radius: 6px;
     display: inline-block;
     padding: 10px 18px 9px 40px;
     font-weight: 500;
     box-shadow: 0 0 8px rgba(0, 0, 0, .2), 0 0 4px rgba(0, 0, 0, .25);
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translate3d(-50%, -50%, 0);
     z-index: 100;
 }

 .arial{
    font-family:"Arial"
 }

  .georgia{
    font-family:"Georgia"
 }

  .impact{
    font-family:"Impact"
 }

  .tahoma{
    font-family:"Tahoma"
 }

  .times{
    font-family:"Times New Roman"
 }

  .verdana {
      font-family: "Verdana"
  }

  .sy_iframe__container_inactive_view {
    background-color: #3c9291;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    
  }

  .sy_iframe__container_logo_and_text {
padding: 2rem;
      margin-top: -10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    position: absolute;
    z-index: 1;
  }

    #sy_iframe__matterspace_logo {
        aspect-ratio: 16/4;
    min-height: 100px;
    height: 10vh;
    }

    .sy_iframe__message_title {
        color: white;
        height: fit-content;
        margin: 0.5rem 0;
    }

    .sy_iframe__message_subtitle {
            color: #d7d7d78f;
    margin: 0.75rem 0;
    }

    .sy_iframe__container_text {
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    #sy_iframe__matterspace_background_particles{
        position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: .5;
    }

    .component_loader__container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
}

.component_loader__spinner_container {
    padding-top: 3rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sy_iframe__input_text {
    margin: 0.5rem 0;
    padding: 0.5rem;
    background: transparent;
    border: 2px solid #ffffff57;
    border-radius: 0.25rem;
    color: white;
    outline: none;
    opacity: .5;
    transition: opacity .3s linear;
    width: 75%;
}

.sy_iframe__input_text::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.sy_iframe__input_text:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

.sy_iframe__input_text::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}

.sy_iframe__input_text:hover, .sy_iframe__input_text:focus,
.sy_iframe__unlock_button:hover, .sy_iframe__unlock_button:focus {
    
    opacity: 1;
}

.sy_iframe__unlock_button:hover, .sy_iframe__unlock_button:focus {

    background: #218382;
    border-color: #218382;
}


.sy_iframe__unlock_button {
    padding: 0.5rem;
    background: transparent;
    color: white;
    border: 2px solid #ffffff57;
    border-radius: 0.25rem;
    margin: 0.5rem 0;
    opacity: .5;
    cursor: pointer;
    transition: background .5s linear;
    width: 100%;
}

.sy_iframe__error_subtitle {
    color: white;
    margin: 0.75rem 0;
}

    .sy_iframe__container_password{
    background: #2b7574;
    border-radius: 0.25rem;
    padding: 1.5rem 5.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    }